import { linesheetApi } from "src/api/linesheet";
import { slice } from "src/slices/linesheet";
import { createAsyncThunk } from "@reduxjs/toolkit";
import Papa from "papaparse";
import * as XLSX from "xlsx";

const deployEnvironment = require("../deployEnvironment.js");

const API = deployEnvironment;

// Brands
const getBrands = () => async (dispatch) => {
  dispatch(slice.actions.brandListLoading());
  const response = await linesheetApi.getBrands();
  dispatch(slice.actions.brandListReceived(response));
};

// Category
const getCategories = () => async (dispatch) => {
  dispatch(slice.actions.categoryListLoading());
  const response = await linesheetApi.getCategories();
  dispatch(slice.actions.categoryListReceived(response));
};

// Season
const getSeasons = () => async (dispatch) => {
  dispatch(slice.actions.seasonListLoading());
  const response = await linesheetApi.getSeasons();
  dispatch(slice.actions.seasonListReceived(response));
};

// Linesheets
const getLinesheetList = () => async (dispatch) => {
  dispatch(slice.actions.linsheetListLoading());
  const response = await linesheetApi.getLinesheetList();
  dispatch(slice.actions.linesheetListReceived(response));
};

const getLinesheet = (linesheetId) => async (dispatch) => {
  dispatch(slice.actions.getLinesheetLoading());
  const response = await linesheetApi.getLinesheet(linesheetId);
  dispatch(slice.actions.getLinesheetReceived(response));
};

// Linesheet History
const getLinesheetHistory = (linesheetId) => async (dispatch) => {
  dispatch(slice.actions.linesheetHistoryLoading());
  const response = await linesheetApi.getLinesheetHistory(linesheetId);
  dispatch(slice.actions.linesheetHistoryReceived(response));
};

// Linesheet Email History
const getLinesheetEmailHistory = (linesheetId) => async (dispatch) => {
  dispatch(slice.actions.linesheetEmailHistoryLoading());
  const response = await linesheetApi.getLinesheetEmailHistory(linesheetId);
  dispatch(slice.actions.linesheetEmailHistoryReceived(response));
};

const getLinesheetForEdit = (linesheetId) => async (dispatch) => {
  dispatch(slice.actions.linsheetLoading());
  const response = await linesheetApi.getLinesheet(linesheetId);
  dispatch(slice.actions.linesheetReceived(response));
};

// Get OPS Support for brand
const getOPSSupportUser = (brand) => async (dispatch) => {
  const response = await linesheetApi.getOPSSupportUser(brand);
  dispatch(slice.actions.setOPSSupportUser(response));
};

// Create Linesheet
const createLinesheet = createAsyncThunk(
  "linesheet/createLinesheet",
  async (data, { rejectWithValue }) => {
    try {
      const response = await linesheetApi.createLinesheet(data);
      return response.data;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

const resetCreateLinesheetErrors = () => (dispatch) => {
  dispatch(slice.actions.resetCreateLinesheetErrors());
};

// Delete Linesheet
const deleteLinesheet = (linesheetId) => async (dispatch) => {
  dispatch(slice.actions.linesheetDeleteLoading());
  const response = await linesheetApi.deleteLinesheet(linesheetId);
  dispatch(slice.actions.linesheetDeleteLoadingReceived(response));
};

const deleteLinesheetRefreshReset = () => async (dispatch) => {
  dispatch(slice.actions.deleteLinesheetRefreshReset());
};

// Update Linesheet
const updateLinesheet = createAsyncThunk(
  "linesheet/updateLinesheet",
  async ({ linesheetId, data }, { rejectWithValue }) => {
    try {
      const response = await linesheetApi.updateLinesheet(linesheetId, data);
      return response.data;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

const resetUpdateLinesheetErrors = () => (dispatch) => {
  dispatch(slice.actions.resetUpdateLinesheetErrors());
};

// Change Linesheet Status
const changeLinesheetStatus = (linesheetId) => async (dispatch) => {
  dispatch(slice.actions.changeLinesheetStatusLoading());
  const response = await linesheetApi.changeLinesheetStatus(linesheetId);
  dispatch(slice.actions.changeLinesheetStatusReceived(response));
};

// Change Linesheet Price Status
const changeLinesheetPriceStatus = (linesheetId) => async (dispatch) => {
  dispatch(slice.actions.changeLinesheetPriceStatusLoading());
  const response = await linesheetApi.changeLinesheetPriceStatus(linesheetId);
  dispatch(slice.actions.changeLinesheetPriceStatusReceived(response));
};

// Revert Linesheet Price Status
const revertLinesheetPriceStatus = (linesheetId) => async (dispatch) => {
  dispatch(slice.actions.revertLinesheetPriceStatusLoading());
  const response = await linesheetApi.revertLinesheetPriceStatus(linesheetId);
  dispatch(slice.actions.revertLinesheetPriceStatusReceived(response));
};

// Change Linesheet Product Attributes Status
const changeLinesheetProductAttributesStatus =
  (linesheetId) => async (dispatch) => {
    dispatch(slice.actions.changeLinesheetProductAttributesStatusLoading());
    const response = await linesheetApi.changeLinesheetProductAttributesStatus(
      linesheetId
    );
    dispatch(
      slice.actions.changeLinesheetProductAttributesStatusReceived(response)
    );
  };

// Revert Linesheet Product Attributes Status
const revertLinesheetProductAttributesStatus =
  (linesheetId, data) => async (dispatch) => {
    dispatch(slice.actions.revertLinesheetProductAttributesStatusLoading());
    const response = await linesheetApi.revertLinesheetProductAttributesStatus(
      linesheetId,
      data
    );
    dispatch(
      slice.actions.revertLinesheetProductAttributesStatusReceived(response)
    );
  };

const changeLinesheetStatusRefreshReset = () => async (dispatch) => {
  dispatch(slice.actions.resetLinesheetRefresh());
};

const createLinesheetRedirectReset = () => (dispatch) => {
  dispatch(slice.actions.createLinesheetRedirectReset());
};

const updateLinesheetRedirectReset = () => (dispatch) => {
  dispatch(slice.actions.updateLinesheetRedirectReset());
};

// Linesheet Price Lists - Linesheet Item Management
const getLinesheetPriceLists = (linesheetId) => async (dispatch) => {
  dispatch(slice.actions.getLinesheePricetListsLoading());
  const response = await linesheetApi.getLinesheetPriceLists(linesheetId);
  dispatch(slice.actions.getLinesheetPriceListsReceived(response));
};

// Linesheet Price Setup - Price Management
const getLinesheetPriceSetup = (linesheetId) => async (dispatch) => {
  dispatch(slice.actions.getLinesheetPriceSetupLoading());
  const response = await linesheetApi.getLinesheetPriceSetup(linesheetId);
  dispatch(slice.actions.getLinesheetPriceSetupReceived(response));
};

// Linesheet Items References
const getLinesheetItemsReferences = () => async (dispatch) => {
  dispatch(slice.actions.linesheetItemsReferencesLoading());
  const response = await linesheetApi.getLinesheetItemsReferences();
  dispatch(slice.actions.linesheetItemsReferencesReceived(response));
};

// Linesheet Items Autocomplete Data
const getLinesheetItemsAutocompleteData = (linesheetId) => async (dispatch) => {
  dispatch(slice.actions.linesheetItemsAutocompleteDataLoading());
  const response = await linesheetApi.getLinesheetItemsAutocompleteData(
    linesheetId
  );
  dispatch(slice.actions.linesheetItemsAutocompleteDataReceived(response));
};

// Linesheet Items Carryover Data
const getLinesheetItemsCarryoverData = (linesheetId) => async (dispatch) => {
  dispatch(slice.actions.linesheetItemsCarryoverDataLoading());
  const response = await linesheetApi.getLinesheetItemsCarryoverData(
    linesheetId
  );
  dispatch(slice.actions.linesheetItemsCarryoverDataReceived(response));
};

// Linesheet Items
const getLinesheetItemsList = (linesheetId) => async (dispatch) => {
  dispatch(slice.actions.linesheetItemsListLoading());
  const response = await linesheetApi.getLinesheetItemsList(linesheetId);
  dispatch(slice.actions.linesheetItemsListReceived(response));
};

// Bulk
const createUpdateLinesheetItemsList =
  (linesheetId, data) => async (dispatch) => {
    dispatch(slice.actions.linesheetItemsCreateUpdateLoading());
    const response = await linesheetApi.createUpdateLinesheetItems(
      linesheetId,
      data
    );
    dispatch(slice.actions.linesheetItemsCreateUpdateLoadingReceived(response));
  };

const deleteLinesheetItem =
  (linesheetId, linesheetItemId) => async (dispatch) => {
    dispatch(slice.actions.linesheetItemDeleteLoading());
    const response = await linesheetApi.deleteLinesheetItem(
      linesheetId,
      linesheetItemId
    );
    dispatch(slice.actions.linesheetItemDeleteLoadingReceived(response));
  };

const deleteLinesheetItemRefreshReset = () => (dispatch) => {
  dispatch(slice.actions.createUpdateLinesheetItemsRefreshReset());
};

const brandRequestDeleteLinesheetItem =
  (linesheetId, linesheetItemId) => async (dispatch) => {
    dispatch(slice.actions.linesheetItemRequestDeleteLoading());
    const response = await linesheetApi.brandRequestDeleteLinesheetItem(
      linesheetId,
      linesheetItemId
    );
    dispatch(slice.actions.linesheetItemRequestDeleteLoadingReceived(response));
  };

const brandRequestDeleteLinesheetItemRefreshReset = () => (dispatch) => {
  dispatch(slice.actions.createUpdateLinesheetItemsRefreshReset());
};

const createUpdateLinesheetItemsRefreshReset = () => (dispatch) => {
  dispatch(slice.actions.createUpdateLinesheetItemsRefreshReset());
};

const getLinesheetItemsRefreshReset = () => (dispatch) => {
  dispatch(slice.actions.createUpdateLinesheetItemsRefreshReset());
};

const addNewLinesheetItemRow = (data) => (dispatch) => {
  dispatch(slice.actions.addNewLinesheetItemRow(data));
};
const duplicateLinesheetItemRow = (data, duplicatedRowId) => (dispatch) => {
  dispatch(slice.actions.duplicateLinesheetItemRow({ data, duplicatedRowId }));
};

const updateLinesheetItemRowOrderPosition =
  (rowData) => (dispatch, getState) => {
    const { oldIndex, targetIndex, row } = rowData;
    const state = getState();
    const linesheetItems = [...state.linesheet.linesheetItems];

    // Remove the item from the source index
    linesheetItems.splice(oldIndex, 1)[0];

    // Insert the removed item at the destination index
    linesheetItems.splice(targetIndex, 0, row);

    dispatch(slice.actions.updatePositionOrderRowLinesheetItem(linesheetItems));
  };

const editLinesheetItemRow = (data) => (dispatch) => {
  dispatch(slice.actions.editLinesheetItemRow(data));
};

const deleteLinesheetItemRow =
  (linesheetId, linesheetItemId) => async (dispatch) => {
    dispatch(slice.actions.linesheetItemDeleteLoading());
    await linesheetApi.deleteLinesheetItem(linesheetId, linesheetItemId);
    dispatch(slice.actions.linesheetItemDeleteLoadingReceived());
  };

// Linesheet Item (Form)
const getLinesheetItem = (linesheetId, linesheetItemId) => async (dispatch) => {
  dispatch(slice.actions.getLinesheetItemLoading());
  const response = await linesheetApi.getLinesheetItem(
    linesheetId,
    linesheetItemId
  );
  dispatch(slice.actions.getLinesheetItemLoadingReceived(response));
};

const resetLinesheetItem = () => async (dispatch) => {
  dispatch(slice.actions.resetLinesheetItem());
};

const createLinesheetItem = (linesheetId, data) => async (dispatch) => {
  dispatch(slice.actions.linesheetItemCreateLoading());
  const response = await linesheetApi.createLinesheetItem(linesheetId, data);
  dispatch(slice.actions.linesheetItemCreateLoadingReceived(response));
};

const createLinesheetFormItem = (linesheetId, data) => async (dispatch) => {
  dispatch(slice.actions.linesheetItemCreateLoading());
  const response = await linesheetApi.createLinesheetItem(linesheetId, data);
  dispatch(slice.actions.linesheetItemCreateFormLoadingReceived(response));
};

// Blank Linesheet Template
const getLinesheetTemplate = () => async (dispatch) => {
  const response = await linesheetApi.getLinesheetTemplate();
  dispatch(slice.actions.setLinesheetTemplateName(response.name));
};

const uploadLinesheetTemplate =
  (linesheetTemplateFile, oldFileName) => async (dispatch) => {
    dispatch(slice.actions.setUploadingLinesheetTemplate(true));
    await linesheetApi.uploadLinesheetTemplate(
      linesheetTemplateFile,
      oldFileName
    );
    dispatch(slice.actions.setUploadingLinesheetTemplate(false));
  };

// Zedonk Mappings
const getZedonkMappings = (brandName) => async (dispatch) => {
  const response = await linesheetApi.getZedonkMappings(brandName);
  dispatch(slice.actions.setZedonkMappings(response));
};

const updateZedonkMappings = (brandName, values) => async (dispatch) => {
  const response = await linesheetApi.updateZedonkMappings(brandName, values);
  dispatch(slice.actions.setZedonkMappings(response));
};

const getZedonkTemplates = () => async (dispatch) => {
  const response = await linesheetApi.getZedonkTemplates();
  dispatch(slice.actions.setZedonkTemplates(response));
};

// Create Colour Variant (Linesheet Item)
const createColourVariant = (linesheetId, data) => async (dispatch) => {
  dispatch(slice.actions.colourVariantCreateLoading());
  const response = await linesheetApi.createLinesheetItem(linesheetId, data);
  dispatch(slice.actions.colourVariantCreateLoadingReceived(response));
};

const createLinesheetItemRedirectReset = () => async (dispatch) => {
  dispatch(slice.actions.linesheetItemCreateRedirectReset());
};

const updateLinesheetItem =
  (linesheetId, linesheetItemId, data) => async (dispatch) => {
    dispatch(slice.actions.linesheetItemUpdateLoading());
    const response = await linesheetApi.updateLinesheetItem(
      linesheetId,
      linesheetItemId,
      data
    );
    dispatch(slice.actions.linesheetItemUpdateLoadingReceived(response));
  };

const updateLinesheetItemRedirectReset = () => async (dispatch) => {
  dispatch(slice.actions.linesheetItemUpdateRedirectReset());
};

const uploadLinesheetItemsFromExcel = createAsyncThunk(
  "linesheet/uploadLinesheetItemsFromExcel",
  async ({ type, linesheetId, formData }, { rejectWithValue }) => {
    try {
      const response = await linesheetApi.uploadLinesheetItemsFromExcel(
        type,
        linesheetId,
        formData
      );
      return response;
    } catch (error) {
      throw rejectWithValue(error); // Return error payload
    }
  }
);

const uploadLinesheetItemsErrorReset = () => async (dispatch) => {
  dispatch(slice.actions.linesheetItemsImportErrorReset());
};

// Import Linesheet Item from Carryover Modal
const importLinesheetItem = (linesheetId, data) => async (dispatch) => {
  dispatch(slice.actions.linesheetItemImportLoading());
  const response = await linesheetApi.importLinesheetItem(linesheetId, data);
  dispatch(slice.actions.linesheetItemImportLoadingReceived(response));
};

const resetImportLinesheetItem = () => async (dispatch) => {
  dispatch(slice.actions.resetLinesheetItemImport());
};

const resetLinesheetItemsImportParsingFinished = () => async (dispatch) => {
  dispatch(slice.actions.resetLinesheetItemsImportParsingFinished());
};

// Used for Linesheets with Brand Suggested Prices
const exportLinesheetItemsToCSV = (linesheetId) => async (dispatch) => {
  const result = await linesheetApi.exportLinesheetItems(linesheetId);

  const csv = Papa.unparse(result.items);
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");

  link.setAttribute("href", url);
  link.setAttribute(
    "download",
    `Linesheet ${result.details.name} ${result.details.season} export for NetSuite.csv`
  );
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

// Used For Linesheets With Brand Suggested Prices
const exportLinesheetItemsToExcel = (linesheetId) => async (dispatch) => {
  const result = await linesheetApi.exportLinesheetItems(linesheetId);

  // Create a worksheet from the data
  const ws = XLSX.utils.json_to_sheet(result.items);

  // Create a workbook with the worksheet
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  // Convert the workbook to an array buffer
  const excelFile = XLSX.write(wb, { bookType: "xlsx", type: "array" });

  // Convert the array buffer to a blob
  const blob = new Blob([excelFile], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  // Create a URL for the blob
  const url = URL.createObjectURL(blob);

  // Create a link element to trigger the download
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute(
    "download",
    `Linesheet ${result.details.name} ${result.details.season} export.xlsx`
  );

  // Trigger a click event to start the download
  link.click();

  // Clean up by revoking the URL object
  URL.revokeObjectURL(url);
};

// Used For Price Management With Calculated Prices
const exportLinesheetItemsToCSVPriceManagement =
  (linesheetId) => async (dispatch) => {
    const result = await linesheetApi.exportLinesheetItemsPriceManagement(
      linesheetId
    );

    const csv = Papa.unparse(result.items);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");

    link.setAttribute("href", url);
    link.setAttribute(
      "download",
      `Linesheet ${result.details.name} ${result.details.season} export for NetSuite.csv`
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

// Used For Price Management With Suggested/Calculated Prices
const exportLinesheetItemsToExcelPriceManagement =
  (linesheetId) => async (dispatch) => {
    const result = await linesheetApi.exportLinesheetItemsExcelPriceManagement(
      linesheetId
    );

    // Create a worksheet from the data
    const ws = XLSX.utils.json_to_sheet(result.items);

    // Create a workbook with the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Convert the workbook to an array buffer
    const excelFile = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    // Convert the array buffer to a blob
    const blob = new Blob([excelFile], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create a URL for the blob
    const url = URL.createObjectURL(blob);

    // Create a link element to trigger the download
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `Linesheet ${result.details.name} ${result.details.season} export.xlsx`
    );

    // Trigger a click event to start the download
    link.click();

    // Clean up by revoking the URL object
    URL.revokeObjectURL(url);
  };

// Download Linesheet Template
const downloadLinesheetTemplate = () => async (dispatch) => {
  const result = await linesheetApi.downloadLinesheetTemplate();

  // Create a temporary anchor element
  const link = document.createElement("a");
  link.href = `${API}${result.url}`;

  // Trigger a click event on the anchor to start the download
  link.click();
};

// Sync Linesheet To Netsuite (Linesheets Table)
const syncLinesheetToNetsuiteTablePage =
  (linesheetId, data) => async (dispatch) => {
    dispatch(slice.actions.syncLinesheetToNetsuiteLoading());
    const response = await linesheetApi.syncLinesheetToNetsuite(
      linesheetId,
      data
    );
    dispatch(slice.actions.syncLinesheetToNetsuiteReceived(response));
  };

// Sync Linesheet To Netsuite (Linesheet details page)
const syncLinesheetToNetsuiteDetailsPage =
  (linesheetId, data) => async (dispatch) => {
    dispatch(slice.actions.syncLinesheetToNetsuiteLoadingDetailsPage());
    const response = await linesheetApi.syncLinesheetToNetsuite(
      linesheetId,
      data
    );
    dispatch(
      slice.actions.syncLinesheetToNetsuiteReceivedDetailsPage(response)
    );
  };

// Linesheet Netsuite Items
const getLinesheetNetsuiteItems = (linesheetId) => async (dispatch) => {
  dispatch(slice.actions.linesheetNetsuiteItemsLoading());
  const response = await linesheetApi.getLinesheetNetsuiteItems(linesheetId);
  dispatch(slice.actions.linesheetNetsuiteItemsReceived(response));
};

export const thunks = {
  // Linesheet
  getBrands,
  getCategories,
  getSeasons,
  getLinesheetList,
  createLinesheet,
  deleteLinesheet,
  deleteLinesheetRefreshReset,
  getLinesheet,
  getLinesheetHistory,
  getLinesheetEmailHistory,
  getLinesheetForEdit,
  getOPSSupportUser,
  updateLinesheet,
  changeLinesheetStatus,
  changeLinesheetPriceStatus,
  revertLinesheetPriceStatus,
  changeLinesheetStatusRefreshReset,
  changeLinesheetProductAttributesStatus,
  revertLinesheetProductAttributesStatus,
  createLinesheetRedirectReset,
  updateLinesheetRedirectReset,
  getLinesheetPriceLists,
  getLinesheetPriceSetup,
  resetCreateLinesheetErrors,
  resetUpdateLinesheetErrors,

  // Blank Linesheet Template
  getLinesheetTemplate,
  uploadLinesheetTemplate,

  // Zedonk Mappings
  getZedonkMappings,
  updateZedonkMappings,
  getZedonkTemplates,

  // Linesheet Items
  getLinesheetItemsReferences,
  getLinesheetItemsAutocompleteData,
  getLinesheetItemsCarryoverData,
  getLinesheetItemsList,
  getLinesheetItemsRefreshReset,
  createUpdateLinesheetItemsList,
  createUpdateLinesheetItemsRefreshReset,
  deleteLinesheetItem,
  deleteLinesheetItemRefreshReset,
  brandRequestDeleteLinesheetItem,
  brandRequestDeleteLinesheetItemRefreshReset,
  uploadLinesheetItemsFromExcel,
  uploadLinesheetItemsErrorReset,
  importLinesheetItem,
  resetImportLinesheetItem,
  resetLinesheetItemsImportParsingFinished,
  exportLinesheetItemsToCSV,
  exportLinesheetItemsToExcel,
  exportLinesheetItemsToCSVPriceManagement,
  exportLinesheetItemsToExcelPriceManagement,
  downloadLinesheetTemplate,

  // Linesheet Items Rows Actions (Data-Grid)
  addNewLinesheetItemRow,
  duplicateLinesheetItemRow,
  updateLinesheetItemRowOrderPosition,
  editLinesheetItemRow,
  deleteLinesheetItemRow,

  // Linesheet item (Create/Update Form)
  getLinesheetItem,
  createLinesheetItem,
  createLinesheetFormItem,
  createColourVariant,
  createLinesheetItemRedirectReset,
  updateLinesheetItem,
  updateLinesheetItemRedirectReset,
  resetLinesheetItem,

  // Netsuite
  syncLinesheetToNetsuiteTablePage,
  syncLinesheetToNetsuiteDetailsPage,

  // Linesheet Netsuite Items
  getLinesheetNetsuiteItems,
};
