import { createSlice } from "@reduxjs/toolkit";
import { thunks } from "src/thunks/linesheet";

const linesheetState = {
  // Linesheet
  linesheets: [],
  brands: [],
  categories: [],
  seasons: [],
  newLinesheet: {},
  editLinesheet: {},
  linesheet: {},
  priceLists: {},
  priceSetup: {},

  // Linesheet
  deleteLinesheetLoading: false,
  refreshLinesheetList: false,

  // Linesheet Item References
  linesheetItemReferences: {},

  // Linesheet Item Autocomplete Data
  linesheetItemAutocompleteData: {},

  // Linesheet Item Carryover Data
  linesheetItemCarryoverData: [],

  // Linesheet Items (Table-Bulk)
  linesheetItems: [],
  linesheetItemsCreateUpdate: [],

  // Linesheet item (Form)
  linesheetItem: {},
  linesheetItemCreate: {},
  linesheetItemUpdate: {},

  // Linesheet History
  linesheetHistory: {},

  linesheetHistoryLoading: false,

  // Linesheet Email History
  linesheetEmailHistory: {},

  linesheetEmailHistoryLoading: false,

  // Create Linesheet Errors
  createLinesheetErrors: null,

  // Update Linesheet Errors
  updateLinesheetErrors: null,

  // Import Excel Errors
  importExcelErrors: null,

  // Linesheet Loading/Redirect
  linesheetsLoading: false,
  brandsLoading: false,
  categoriesLoading: false,
  seasonsLoading: false,
  editLinesheetLoading: false,
  linesheetLoading: false,
  priceListsLoading: false,
  priceSetupLoading: false,

  newLinesheetInProgress: false,
  newLinesheetRedirect: false,

  updateLinesheetInProgress: false,
  updateLinesheetRedirect: false,

  refreshLinesheet: false,
  refreshLinesheetPriceLists: false,
  refreshLinesheetPriceSetup: false,

  // Change Linesheet Status/Price Status/Product Attributes Status
  changeLinesheetStatusInProgress: false,
  changeLinesheetPriceStatusInProgress: false,
  revertLinehseetPriceStatusInProgress: false,
  changeLinesheetProductAttributesStatusInProgress: false,
  revertLinesheetProductAttributesStatusInProgress: false,

  // Linesheet Items References Loading
  linesheetItemsReferencesLoading: false,

  // Linesheet Items Autocomplete Data Loading
  linesheetItemsAutocompleteDataLoading: false,

  // Linesheet Items Carryover Data Loading
  linesheetItemsCarryoverDataLoading: false,

  // Linesheet Items Loading (Create/Update/Delete Table-Bulk)
  linesheetItemsLoading: false,
  createUpdateLinesheetItemsLoading: false,
  deleteLinesheetItemLoading: false,
  requestDeleteLinesheetItemLoading: false,

  // Linesheet Items Created/Updated
  refreshLinesheetItemsList: false,

  // Linesheet Item Get (Create/Update Forms)
  linesheetItemLoading: false,

  // Linesheet Item Loading (Create/Update Forms)
  createLinesheetItemLoading: false,
  updateLinesheetItemLoading: false,

  newLinesheetItemRedirect: false,
  updateLinesheetItemRedirect: false,

  // Linesheet Items Import
  linesheetItemsFromExcel: [],
  linesheetItemsImportLoading: false,
  linesheetItemsImportParsingFinished: false,

  // Linesheet Item Import
  linesheetItemImport: null,
  linesheetItemImportLoading: false,

  // Carryover Items
  importCarryoverLinesheetItems: [],

  opsSupportUser: {},

  // Sync Linesheet To Netsuite
  syncLinesheetToNetsuiteLoading: false,

  // Linesheet Netsuite Items
  linesheetNetsuiteItems: [],
  linesheetNetsuiteItemsLoading: false,

  // Blank Linesheet Template
  linesheetTemplateName: "",
  uploadingLinesheetTemplate: false,

  // Zedonk Mappings
  initialZedonkMappings: null,
  zedonkTemplates: null,
};

const reducers = {
  // Linesheet List
  linsheetListLoading(state) {
    if (!state.linesheetsLoading) {
      state.linesheetsLoading = true;
    }
  },
  linesheetListReceived(state, action) {
    if (state.linesheetsLoading) {
      state.linesheetsLoading = false;
      state.linesheets = action.payload;
    }
  },

  // Brand List
  brandListLoading(state) {
    if (!state.brandsLoading) {
      state.brandsLoading = true;
    }
  },
  brandListReceived(state, action) {
    if (state.brandsLoading) {
      state.brandsLoading = false;
      state.brands = action.payload;
    }
  },

  // Categories List
  categoryListLoading(state) {
    if (!state.categoriesLoading) {
      state.categoriesLoading = true;
    }
  },
  categoryListReceived(state, action) {
    if (state.categoriesLoading) {
      state.categoriesLoading = false;
      state.categories = action.payload;
    }
  },

  // Season List
  seasonListLoading(state) {
    if (!state.seasonsLoading) {
      state.seasonsLoading = true;
    }
  },
  seasonListReceived(state, action) {
    if (state.seasonsLoading) {
      state.seasonsLoading = false;
      state.seasons = action.payload;
    }
  },

  // Get Linesheet
  getLinesheetLoading(state) {
    if (!state.linesheetLoading) {
      state.linesheetLoading = true;
    }
  },
  getLinesheetReceived(state, action) {
    if (state.linesheetLoading) {
      state.linesheetLoading = false;
      state.linesheet = action.payload;
    }
  },

  // Get Linesheet for Edit
  linsheetLoading(state) {
    if (!state.editLinesheetLoading) {
      state.editLinesheetLoading = true;
    }
  },
  linesheetReceived(state, action) {
    if (state.editLinesheetLoading) {
      state.editLinesheetLoading = false;
      state.editLinesheet = action.payload;
    }
  },

  // Linesheet History
  linesheetHistoryLoading(state) {
    if (!state.linesheetHistoryLoading) {
      state.linesheetHistoryLoading = true;
    }
  },
  linesheetHistoryReceived(state, action) {
    if (state.linesheetHistoryLoading) {
      state.linesheetHistoryLoading = false;
      state.linesheetHistory = action.payload;
    }
  },

  // Linesheet Email History
  linesheetEmailHistoryLoading(state) {
    if (!state.linesheetEmailHistoryLoading) {
      state.linesheetEmailHistoryLoading = true;
    }
  },
  linesheetEmailHistoryReceived(state, action) {
    if (state.linesheetEmailHistoryLoading) {
      state.linesheetEmailHistoryLoading = false;
      state.linesheetEmailHistory = action.payload;
    }
  },

  // Create Linesheet
  createLinesheetRedirectReset(state) {
    state.newLinesheetRedirect = false;
  },

  resetCreateLinesheetErrors(state) {
    state.createLinesheetErrors = null;
  },

  // Delete Linesheet
  linesheetDeleteLoading(state) {
    if (!state.deleteLinesheetLoading) {
      state.deleteLinesheetLoading = true;
    }
  },

  linesheetDeleteLoadingReceived(state, action) {
    if (state.deleteLinesheetLoading) {
      state.deleteLinesheetLoading = false;
      state.refreshLinesheetList = true;
    }
  },

  deleteLinesheetRefreshReset(state, action) {
    state.refreshLinesheetList = false;
  },

  // Update Linesheet
  updateLinesheetRedirectReset(state) {
    state.updateLinesheetRedirect = false;
  },

  resetUpdateLinesheetErrors(state) {
    state.updateLinesheetErrors = null;
  },

  // Change Linesheet Status
  changeLinesheetStatusLoading(state) {
    if (!state.changeLinesheetStatusInProgress) {
      state.changeLinesheetStatusInProgress = true;
    }
  },
  changeLinesheetStatusReceived(state, action) {
    if (state.changeLinesheetStatusInProgress) {
      state.changeLinesheetStatusInProgress = false;
      state.refreshLinesheet = true;
    }
  },
  resetLinesheetRefresh(state, action) {
    state.refreshLinesheet = false;
  },

  // Change Linesheet Price Status
  changeLinesheetPriceStatusLoading(state) {
    if (!state.changeLinesheetPriceStatusInProgress) {
      state.changeLinesheetPriceStatusInProgress = true;
    }
  },
  changeLinesheetPriceStatusReceived(state, action) {
    if (state.changeLinesheetPriceStatusInProgress) {
      state.changeLinesheetPriceStatusInProgress = false;
      state.refreshLinesheet = true;
    }
  },

  // Revert Linesheet Price Status
  revertLinesheetPriceStatusLoading(state) {
    if (!state.revertLinehseetPriceStatusInProgress) {
      state.revertLinehseetPriceStatusInProgress = true;
    }
  },
  revertLinesheetPriceStatusReceived(state, action) {
    if (state.revertLinehseetPriceStatusInProgress) {
      state.revertLinehseetPriceStatusInProgress = false;
      state.refreshLinesheetItemsList = true;
    }
  },

  // Change Linesheet Product Attributes Status
  changeLinesheetProductAttributesStatusLoading(state) {
    if (!state.changeLinesheetProductAttributesStatusInProgress) {
      state.changeLinesheetProductAttributesStatusInProgress = true;
    }
  },
  changeLinesheetProductAttributesStatusReceived(state, action) {
    if (state.changeLinesheetProductAttributesStatusInProgress) {
      state.changeLinesheetProductAttributesStatusInProgress = false;
      state.refreshLinesheet = true;
    }
  },

  // Revert Linesheet Product Attributes Status
  revertLinesheetProductAttributesStatusLoading(state) {
    if (!state.revertLinesheetProductAttributesStatusInProgress) {
      state.revertLinesheetProductAttributesStatusInProgress = true;
    }
  },
  revertLinesheetProductAttributesStatusReceived(state, action) {
    if (state.revertLinesheetProductAttributesStatusInProgress) {
      state.revertLinesheetProductAttributesStatusInProgress = false;
      state.refreshLinesheetItemsList = true;
    }
  },

  // Linesheet Price Lists - Linesheet Item Management
  getLinesheePricetListsLoading(state) {
    if (!state.priceListsLoading) {
      state.priceListsLoading = true;
    }
  },
  getLinesheetPriceListsReceived(state, action) {
    if (state.priceListsLoading) {
      state.priceListsLoading = false;
      state.priceLists = action.payload;
      state.refreshLinesheetPriceLists = true;
    }
  },
  resetLinesheetPriceLists(state, action) {
    state.refreshLinesheetPriceLists = false;
  },

  // Linesheet Price Setup - Price Management
  getLinesheetPriceSetupLoading(state) {
    if (!state.priceSetupLoading) {
      state.priceSetupLoading = true;
    }
  },
  getLinesheetPriceSetupReceived(state, action) {
    if (state.priceSetupLoading) {
      state.priceSetupLoading = false;
      state.priceSetup = action.payload;
      state.refreshLinesheetPriceSetup = true;
    }
  },
  resetLinesheetPriceSetup(state, action) {
    state.refreshLinesheetPriceSetup = false;
  },

  // Linesheet Items References
  linesheetItemsReferencesLoading(state) {
    if (!state.linesheetItemsReferencesLoading) {
      state.linesheetItemsReferencesLoading = true;
    }
  },
  linesheetItemsReferencesReceived(state, action) {
    if (state.linesheetItemsReferencesLoading) {
      state.linesheetItemsReferencesLoading = false;
      state.linesheetItemReferences = action.payload;
    }
  },

  // Linesheet Items Autocomplete Data
  linesheetItemsAutocompleteDataLoading(state) {
    if (!state.linesheetItemsAutocompleteDataLoading) {
      state.linesheetItemsAutocompleteDataLoading = true;
    }
  },
  linesheetItemsAutocompleteDataReceived(state, action) {
    if (state.linesheetItemsAutocompleteDataLoading) {
      state.linesheetItemsAutocompleteDataLoading = false;
      state.linesheetItemAutocompleteData = action.payload;
    }
  },

  // Linesheet Items Carryover Data
  linesheetItemsCarryoverDataLoading(state) {
    if (!state.linesheetItemsCarryoverDataLoading) {
      state.linesheetItemsCarryoverDataLoading = true;
    }
  },
  linesheetItemsCarryoverDataReceived(state, action) {
    if (state.linesheetItemsCarryoverDataLoading) {
      state.linesheetItemsCarryoverDataLoading = false;
      state.linesheetItemCarryoverData = action.payload;
    }
  },

  // Linesheet Items List
  linesheetItemsListLoading(state) {
    if (!state.linesheetItemsLoading) {
      state.linesheetItemsLoading = true;
    }
  },

  linesheetItemsListReceived(state, action) {
    if (state.linesheetItemsLoading) {
      state.linesheetItemsLoading = false;

      // Parse Linesheet Items from API to match the table Column Definition
      const linesheetItemsRows = action.payload.map((item) => {
        return {
          id: item.id,
          position: item.position,
          netsuiteStyleCode: item.netsuiteStyleCode,
          styleCode: item.styleCode,
          styleName: item.styleName,
          styleDescription: item.styleDescription,
          gender: item.gender,
          productCategory: item.productCategory,
          productSubCategory: item.productSubCategory,
          colourDescription: item.colourDescription,
          primaryColour: item.primaryColour,
          secondaryColour: item.secondaryColour,
          netsuiteColourCode: item.netsuiteColourCode,
          materialComposition: item.materialComposition,
          customsClassification: item.customsClassification,
          commodityCode: item.commodityCode,
          countryOfOrigin: item.countryOfOrigin,
          sizeRunCategory: item.sizeRunCategory,
          fullSizeRun: item.fullSizeRun,
          sizesAvailableWithinFullSizeRun: item.sizesAvailableWithinFullSizeRun,
          linesheetName: item.linesheetName,
          priceList1: item.priceList1,
          currency1: item.currency1,
          purchasePriceCurrency1: item.purchasePriceCurrency1,
          brandPurchasePrice1: item.brandPurchasePrice1,
          purchasePrice1: item.purchasePrice1,
          brandWholesalePrice1: item.brandWholesalePrice1,
          wholesalePrice1: item.wholesalePrice1,
          brandRetailPrice1: item.brandRetailPrice1,
          retailPrice1: item.retailPrice1,
          created_by: item.user?.email,
          created_at: item.createdAt,
          updated_at: item.updatedAt,
          user: item?.user,
          prices: item.prices,
          notes: item.notes,
          linesheet: item?.linesheet,
          edit: false,
          carryover: item.carryover,
          colourVariant: item.colourVariant,
          carryoverImport: item.carryoverImport,
          linesheetItemHistories: item.linesheet_item_histories,
          recordHistory: item.recordHistory,
          newItemSinceBrandConfirm: item.newItemSinceBrandConfirm,
          brandDeleteRequest: item.brandDeleteRequest,
          pricesOverwrite: item.pricesOverwrite,
          manufacturer: item.manufacturer,
        };
      });

      state.linesheetItems = linesheetItemsRows;
    }
  },

  // Linesheet Items Create/Update --Table Bulk--
  linesheetItemsCreateUpdateLoading(state) {
    if (!state.createUpdateLinesheetItemsLoading) {
      state.createUpdateLinesheetItemsLoading = true;
    }
  },
  linesheetItemsCreateUpdateLoadingReceived(state, action) {
    if (state.createUpdateLinesheetItemsLoading) {
      state.createUpdateLinesheetItemsLoading = false;
      state.refreshLinesheetItemsList = true;
      state.linesheetItemsCreateUpdate = action.payload;
    }
  },

  createUpdateLinesheetItemsRefreshReset(state) {
    state.refreshLinesheetItemsList = false;
  },

  // Linesheet Item - Add new item Row
  addNewLinesheetItemRow(state, action) {
    state.linesheetItems = [...state.linesheetItems, action.payload];
  },

  // Linesheet Item - Duplicate item
  duplicateLinesheetItemRow(state, action) {
    const newDuplicatedRowData = action.payload.data;
    const duplicatedRowId = action.payload.duplicatedRowId;

    const objectIndex = state.linesheetItems.findIndex(
      (object) => object.id === duplicatedRowId
    );

    // Add duplicated Row after the target duplicate row
    state.linesheetItems.splice(objectIndex + 1, 0, newDuplicatedRowData);
  },

  // Linesheet Items - Handle Update Row Order Position
  updatePositionOrderRowLinesheetItem(state, action) {
    state.linesheetItems = action.payload;
  },

  // Linesheet Item - Edit item
  editLinesheetItemRow(state, action) {
    state.linesheetItems = [...state.linesheetItems].map((row) => {
      if (row.id === action.payload.id) {
        // Set edit flag to true
        if (!action.payload.new) {
          action.payload.edit = true;
        }

        return action.payload;
      }

      return row;
    });
  },

  // Linesheet Item Delete
  linesheetItemDeleteLoading(state) {
    if (!state.deleteLinesheetItemLoading) {
      state.deleteLinesheetItemLoading = true;
    }
  },

  linesheetItemDeleteLoadingReceived(state, action) {
    if (state.deleteLinesheetItemLoading) {
      state.deleteLinesheetItemLoading = false;
      state.refreshLinesheetItemsList = true;
    }
  },

  // Linesheet Item Request Delete (Brand)
  linesheetItemRequestDeleteLoading(state) {
    if (!state.requestDeleteLinesheetItemLoading) {
      state.requestDeleteLinesheetItemLoading = true;
    }
  },

  linesheetItemRequestDeleteLoadingReceived(state, action) {
    if (state.requestDeleteLinesheetItemLoading) {
      state.requestDeleteLinesheetItemLoading = false;
      state.refreshLinesheetItemsList = true;
    }
  },

  // Linesheet Item (Get) --Forms--
  getLinesheetItemLoading(state) {
    if (!state.linesheetItemLoading) {
      state.linesheetItemLoading = true;
    }
  },
  getLinesheetItemLoadingReceived(state, action) {
    if (state.linesheetItemLoading) {
      state.linesheetItemLoading = false;
      state.linesheetItem = action.payload;
    }
  },
  resetLinesheetItem(state) {
    state.linesheetItem = {};
  },

  // Linesheet Item (Create) --Forms--
  linesheetItemCreateLoading(state) {
    if (!state.createLinesheetItemLoading) {
      state.createLinesheetItemLoading = true;
    }
  },
  linesheetItemCreateLoadingReceived(state, action) {
    if (state.createLinesheetItemLoading) {
      state.createLinesheetItemLoading = false;
      state.linesheetItemCreate = action.payload;
      state.newLinesheetItemRedirect = true;
    }
  },
  linesheetItemCreateFormLoadingReceived(state, action) {
    if (state.createLinesheetItemLoading) {
      state.createLinesheetItemLoading = false;
      state.linesheetItemCreate = action.payload;
    }
  },
  linesheetItemCreateRedirectReset(state) {
    state.newLinesheetItemRedirect = false;
  },

  // Colour Variant (Linesheet Item) - (Create/Edit) --Forms--
  colourVariantCreateLoading(state) {
    if (!state.createLinesheetItemLoading) {
      state.createLinesheetItemLoading = true;
    }
  },
  colourVariantCreateLoadingReceived(state, action) {
    if (state.createLinesheetItemLoading) {
      state.createLinesheetItemLoading = false;
      state.linesheetItemCreate = action.payload;
      state.refreshLinesheetItemsList = true;
    }
  },

  // Linesheet Item (Update) --Forms--
  linesheetItemUpdateLoading(state) {
    if (!state.updateLinesheetItemLoading) {
      state.updateLinesheetItemLoading = true;
    }
  },
  linesheetItemUpdateLoadingReceived(state, action) {
    if (state.updateLinesheetItemLoading) {
      state.updateLinesheetItemLoading = false;
      state.linesheetItemUpdate = action.payload;
      state.updateLinesheetItemRedirect = true;
    }
  },
  linesheetItemUpdateRedirectReset(state) {
    state.updateLinesheetItemRedirect = false;
  },

  linesheetItemsImportErrorReset(state) {
    state.importExcelErrors = null;
  },

  // Linesheet Item Import (Carryover)
  linesheetItemImportLoading(state) {
    if (!state.linesheetItemImportLoading) {
      state.linesheetItemImportLoading = true;
    }
  },
  linesheetItemImportLoadingReceived(state, action) {
    if (state.linesheetItemImportLoading) {
      state.linesheetItemImportLoading = false;
      state.linesheetItemImport = action.payload;
    }
  },
  resetLinesheetItemsImportParsingFinished(state) {
    state.linesheetItemsImportParsingFinished = false;
  },
  resetLinesheetItemImport(state) {
    state.linesheetItemImport = null;
  },
  setOPSSupportUser(state, action) {
    state.opsSupportUser = action.payload;
  },

  // Sync Linesheet To Netsuite (Linesheets Table Page)
  syncLinesheetToNetsuiteLoading(state) {
    if (!state.syncLinesheetToNetsuiteLoading) {
      state.syncLinesheetToNetsuiteLoading = true;
    }
  },

  syncLinesheetToNetsuiteReceived(state, action) {
    if (state.syncLinesheetToNetsuiteLoading) {
      state.syncLinesheetToNetsuiteLoading = false;
      state.refreshLinesheetList = true;
    }
  },

  // Sync Linesheet To Netsuite (Linesheets Details Page)
  syncLinesheetToNetsuiteLoadingDetailsPage(state) {
    if (!state.syncLinesheetToNetsuiteLoading) {
      state.syncLinesheetToNetsuiteLoading = true;
    }
  },

  syncLinesheetToNetsuiteReceivedDetailsPage(state, action) {
    if (state.syncLinesheetToNetsuiteLoading) {
      state.syncLinesheetToNetsuiteLoading = false;
      state.refreshLinesheet = true;
    }
  },

  // Linesheet Netsuite Items
  linesheetNetsuiteItemsLoading(state) {
    if (!state.linesheetNetsuiteItemsLoading) {
      state.linesheetNetsuiteItemsLoading = true;
    }
  },
  linesheetNetsuiteItemsReceived(state, action) {
    if (state.linesheetNetsuiteItemsLoading) {
      state.linesheetNetsuiteItemsLoading = false;
      state.linesheetNetsuiteItems = action.payload;
    }
  },

  // Blank Linesheet Template
  setLinesheetTemplateName(state, action) {
    state.linesheetTemplateName = action.payload;
  },
  setUploadingLinesheetTemplate(state, action) {
    state.uploadingLinesheetTemplate = action.payload;
  },

  // Zedonk Mappings
  setZedonkMappings(state, action) {
    state.initialZedonkMappings = action.payload;
  },
  setZedonkTemplates(state, action) {
    state.zedonkTemplates = action.payload;
  },
};

const extraReducers = {
  // Create Linesheet
  [thunks.createLinesheet.pending]: (state, action) => {
    state.newLinesheetInProgress = true;
  },
  [thunks.createLinesheet.fulfilled]: (state, action) => {
    state.newLinesheetInProgress = false;
    state.newLinesheetRedirect = true;
  },
  [thunks.createLinesheet.rejected]: (state, action) => {
    state.newLinesheetInProgress = false;
    state.createLinesheetErrors = action.payload.error.details;
  },
  // Update Linesheet
  [thunks.updateLinesheet.pending]: (state, action) => {
    state.updateLinesheetInProgress = true;
  },
  [thunks.updateLinesheet.fulfilled]: (state, action) => {
    state.updateLinesheetInProgress = false;
    state.updateLinesheetRedirect = true;
  },
  [thunks.updateLinesheet.rejected]: (state, action) => {
    state.updateLinesheetInProgress = false;
    state.updateLinesheetErrors = action.payload.error.details;
  },
  // Upload Linesheet Items from Excel
  [thunks.uploadLinesheetItemsFromExcel.pending]: (state, action) => {
    state.linesheetItemsImportLoading = true;
  },
  [thunks.uploadLinesheetItemsFromExcel.fulfilled]: (state, action) => {
    state.linesheetItemsImportLoading = false;
    state.importCarryoverLinesheetItems = action.payload;
    state.refreshLinesheetItemsList = true;
    state.linesheetItemsImportParsingFinished = true;
  },
  [thunks.uploadLinesheetItemsFromExcel.rejected]: (state, action) => {
    state.linesheetItemsImportLoading = false;
    state.importExcelErrors = action.payload.error.details;
  },
};

export const slice = createSlice({
  name: "linesheet",
  initialState: linesheetState,
  reducers,
  extraReducers,
});

export const { reducer } = slice;
